/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import { S3URL } from "../../../constant/thunk";
import Defaultplay from "../../../assets/images/play-button.png";
import { awsPresignedGetUrl } from "../../../helper/awsPresignedUrl";

const SelectContent = (
  mediaType,
  url,
  hasUrl,
  fallBack,
  imgHasLoaded,
  imgLoaded
) => {
  if (mediaType.search("video") >= 0) {
    return (
      <video muted controls width="100%" height="100%" preload="auto">
        <source src={url} type="video/mp4" />
      </video>
    );
  }

  if (mediaType.search("audio") >= 0) {
    return (
      <audio muted preload="auto" controls>
        <source src={hasUrl ? url : fallBack} type={`${mediaType}`} />
      </audio>
    );
  }

  if (mediaType.search("image") >= 0) {
    return (
      <div>
        <img
          src={url}
          width="100%"
          height="100%"
          alt={url}
          style={{ display: imgHasLoaded ? "block" : "none" }}
          onLoad={() => {
            imgLoaded(true);
          }}
        />
        <div className="d-block text-center">
          <svg
            className="contentDetailSpinner"
            viewBox="0 0 50 50"
            style={{ display: imgHasLoaded ? "none" : "block" }}
          >
            <circle
              className="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="4"
            />
          </svg>{" "}
        </div>
      </div>
    );
  }
};

export default class EditModule extends Component {
  static defaultProps = {
    contentType: "",
    localUrl: undefined
  };

  state = {
    url: "",
    hasUrl: false,
    imgHasLoaded: false
  };

  componentDidMount = async () => {
    await this.getS3URL();
  };

  getS3URL = async () => {
    const { awsKey, localUrl, getMethod } = this.props;
    if (awsKey && !localUrl) {
      await awsPresignedGetUrl(awsKey, getMethod).then((resp) => {
        this.setState({
          url: resp,
          hasUrl: true
        });
      });
    }
  };

  imgLoaded = val => {
    this.setState({ imgHasLoaded: val });
  };

  render() {
    const { url, hasUrl } = this.state;
    const { localUrl, localContentType, contentType } = this.props;
    return (
      <div>
        <div className="edit-left" style={{ overflow: "hidden" }}>
          {localUrl ? (
            localContentType.search("video") >= 0 ? (
              <video preload="auto" controls width="100%" height="100%">
                <source src={localUrl} type="video/mp4" />
              </video>
            ) : /**
                 *
                 * TODO: must include the <source> inorder to make the video avaiable in the safari
                 * <video width="400" controls>
                      <source src="https://mps-content.s3.us-east-2.amazonaws.com/Pip%20_%20A%20Short%20Animated%20Film.mp4-5d8bca61-00fe-4fe5-81ac-c31f4d55f026-1549944476?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=AKIAIXBLYVCCCZSOSSIA%2F20190212%2Fus-east-2%2Fs3%2Faws4_request&amp;X-Amz-Date=20190212T102518Z&amp;X-Amz-Expires=604800&amp;X-Amz-Signature=1ba7fff20771e6914f219097e62a6dc25bc829149afeb429a2bae4602b4f7370&amp;X-Amz-SignedHeaders=host"type="video/mp4">
                    </video>
                 *
                 *
                 */
            localContentType.search("audio") >= 0 ? (
              <audio controls preload="auto" width="100%" height="400px" muted>
                <source src={localUrl} />
              </audio>
            ) : (
              <img src={localUrl} alt={url} className="img-fluid" />
            )
          ) : (
            SelectContent(
              contentType,
              url,
              hasUrl,
              Defaultplay,
              this.state.imgHasLoaded,
              this.imgLoaded
            )
          )}
        </div>
      </div>
    );
  }
}
