import { remove } from "lodash";

const postDataObjectMapper = attributes => ({
  _jsonapi: {
    data: [
      {
        type: "content",
        attributes: {
          ...attributes
        }
      }
    ]
  }
});

const presignedUrlObjectMapper = (fileDetails) => ({
  _jsonapi: {
    data: {
      type: "content",
      attributes: {
        files: fileDetails
      }
    }
  }
});

export const postArchivedItems = ({ filteredContentId }) => ({
  _jsonapi: {
    data: {
      type: "content",
      attributes: {
        content_ids: [...filteredContentId]
      }
    }
  }
});

export const removeSingleArchiveItems = id => ({
  _jsonapi: {
    data: {
      type: "content",
      attributes: {
        content_ids: [id]
      }
    }
  }
});

export const unArchiveContents = ({ filteredContentId }) => ({
  _jsonapi: {
    data: {
      type: "content",
      attributes: {
        content_ids: [...filteredContentId]
      }
    }
  }
});

export const Resources = {
  postContent: data => ({
    url: "/contents",
    body: JSON.stringify(postDataObjectMapper(data)),
    asyncActions: {
      init: "postContent_INIT",
      success: "postContent_SUCCESS",
      error: "postContent_ERROR"
    }
  }),
  getPresignedContentUrl: data => ({
    url: "/s3_uploads/generate_presigned_url",
    body: presignedUrlObjectMapper(data),
    asyncActions: {
      init: "getPresignedContentUrl_INIT",
      success: "getPresignedContentUrl_SUCCESS",
      error: "getPresignedContentUrl_ERROR"
    }
  }),
  getContentUrlFromKey: {
    url: "/s3_uploads/s3_url_from_key",
    asyncActions: {
      init: "getContentUrlFromKey_INIT",
      success: "getContentUrlFromKey_SUCCESS",
      error: "getContentUrlFromKey_ERROR"
    }
  },
  getContent: {
    url: "/contents",
    asyncActions: {
      init: "getContent_INIT",
      success: "getContent_SUCCESS",
      error: "getContent_ERROR"
    },
    syncActions: {
      updateList: "getContent_Update"
    }
  },
  getArchivedContent: {
    url: "/contents/archive",
    asyncActions: {
      init: "getArchivedContent_INIT",
      success: "getArchivedContent_SUCCESS",
      error: "getArchivedContent_ERROR"
    }
  },
  unArchiveContent: {
    url: "/contents/unarchive",
    asyncActions: {
      init: "unArchivedContent_INIT",
      success: "unArchivedContent_SUCCESS",
      error: "unArchivedContent_ERROR"
    }
  },
  postArchiveContent: {
    url: "contents/bulk_archive",
    asyncActions: {
      init: "postArchiveContent_INIT",
      success: "postArchiveContent_SUCCESS",
      error: "postArchiveContent_ERROR"
    }
  },
  removeBulkContents: {
    url: "contents/bulk_destroy",
    asyncActions: {
      init: "removeBulkContents_INIT",
      success: "removeBulkContents_SUCCESS",
      error: "removeBulkContents_ERROR"
    }
  },
  deleteContent: id => ({
    url: `/contents/${id}`,
    asyncActions: {
      init: "deleteContent_INIT",
      success: "deleteContent_SUCCESS",
      error: "deleteContent_ERROR"
    }
  }),
  putContent: {
    asyncActions: {
      init: "putContent_INIT",
      success: "putContent_SUCCESS",
      error: "putContent_ERROR"
    }
  }
};

const initialState = {
  list: undefined,
  links: undefined,
  status: undefined,
  statusText: undefined,
  meta: undefined,
  deleted: undefined,
  edited: undefined,
  hasList: undefined,
  loading: true,
  hasError: undefined,
  url: "http://www.dummy.com?page=1&per_page=20"
};

export function AddItemToContentList(item) {
  const { updateList } = Resources.getContent.syncActions;

  return (dispatch, getState) => {
    const { list } = getState().content;
    list.push(item);
    dispatch({ type: updateList, payload: [...list] });
  };
}

export function AddContentFromPutRequest(item, id) {
  const { updateList } = Resources.getContent.syncActions;

  return (dispatch, getState) => {
    const { list } = getState().content;
    remove(list, { id });
    list.push({ ...item.data });
    dispatch({ type: updateList, payload: [...list] });
  };
}

export function RemoveItemFromContentList(id) {
  const { updateList } = Resources.getContent.syncActions;
  return (dispatch, getState) => {
    const { list } = getState().content;
    const newlist = list.filter(item => item.id !== id);
    dispatch({ type: updateList, payload: [...newlist] });
  };
}

// export function RemoveItemFromArchiveContentList(id) {}

export function content(state = initialState, action = {}) {
  const { init, success, error } = Resources.getContent.asyncActions;
  const { updateList } = Resources.getContent.syncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        hasError: undefined,
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      const url = action.payload.request.responseURL;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false,
        url
      };
    }

    case updateList: {
      return {
        ...state,
        list: [...action.payload]
      };
    }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}

export function archivedContent(state = initialState, action = {}) {
  const { init, success, error } = Resources.getArchivedContent.asyncActions;

  switch (action.type) {
    case init: {
      return {
        ...state,
        hasError: undefined
      };
    }

    case success: {
      const {
        data: { data, links, meta },
        status,
        statusText
      } = action.payload;
      const url = action.payload.request.responseURL;
      return {
        list: data,
        links,
        status,
        statusText,
        meta,
        deleted: [],
        edited: [],
        hasList: true,
        loading: false,
        hasError: false,
        url
      };
    }

    // case updateList: {
    //   return {
    //     ...state,
    //     list: [...action.payload]
    //   };
    // }

    case error: {
      return {
        ...state,
        loading: false,
        hasError: true
      };
    }

    default: {
      return state;
    }
  }
}
