import { Resources as reduxResources } from "../features/contents/redux";

export async function awsPresignedUrl(file_detail, Post) {
  try {
    const { getPresignedContentUrl } = reduxResources;
    const result = await Post(getPresignedContentUrl(file_detail));

    if (result.status === 200) {
      return result.data.presigned_urls;
    } else {
      throw new Error("Failed to get presigned URL");
    }
  } catch (error) {
    console.error("Error fetching presigned URL:", error);
    throw error;
  }
}

export async function awsPresignedGetUrl(file_key, Get) {
  try {
    const { getContentUrlFromKey } = reduxResources;
    reduxResources.getContentUrlFromKey.url = `/s3_uploads/s3_url_from_key?file_key=${file_key}`
    const result = await Get(getContentUrlFromKey);

    if (result.status === 200) {
      return result.data.presigned_url;
    } else {
      throw new Error("Failed to get presigned URL");
    }
  } catch (error) {
    console.error("Error fetching presigned URL:", error);
    throw error;
  }
}
