import React from "react";
import { Link } from "react-router-dom";
import { Button, Form, FormGroup, Label } from "reactstrap";
import { remove, uniq, toLower, startCase } from "lodash";
import { connect } from "react-redux";
import {
  formValueSelector,
  reduxForm,
  change,
  Field,
  reset,
  destroy
} from "redux-form";
import moment from "moment";
import { all } from "any-promise";
import { Tooltip, TagInput } from "../../../core";
import FeatureAllowed from "../../../helper/isFeaturedAllowed";
/** Redux library import */
import S3 from "../../../constant/aws";
import {
  progress,
  removeProgress,
  Resources,
  AddItemToContentList,
  ContentTagResources,
  SponserTagResources,
  PartyTagResources,
  BrandTagResources
} from "../redux";
import GenerateForms from "../forms/generateForm";
import { Post } from "../../../constant/thunk";
import { alphaNumeric } from "../../../helper/validation";
import { TagSelect } from "../../../core/form/tagSelect";
import { SingleTagSelect } from "../../../core/form/singleTagSelect";
import { createUniqueTags } from "../../../helper/unitConvert";
import RouteAllowed from "../../../helper/isRouteAllowed";
import axios from "axios";
import { awsPresignedUrl } from "../../../helper/awsPresignedUrl";

/** Form Inputs */
const selector = formValueSelector("ContentUploadForms");
const contentSelector = formValueSelector("Contents");

/** Form Submit Function */

class UploadNewContent extends React.Component {
  constructor(props) {
    super(props);
    this.perPageCount = 20;
    // if (props.files.length === 0) {
    // props.history.push({
    // pathname: "/dashboard/content/create",
    // state: { fromContentUpload: true }
    // });
    // }
    this.state = {
      displayBulkTagForm: false,
      isParty: true
    };
  }
  // componentWillReceiveProps(nextProps) {
  // if (nextProps.files.length === 0 || nextProps.files === undefined) {
  // this.props.history.push({
  // pathname: "/dashboard/content/create",
  // state: { fromContentUpload: true }
  // });
  // }
  // }

  updateToster = (p, item, length, request) => {
    this.props.progress(p, item, length, request);
    return 0;
  };

  updateServer = (awsResult, item) => {
    const { postContent } = Resources;
    Resources.getContent.url = `/contents?page=${1}&per_page=${this.perPageCount
      }`;
    this.props
      .Post(
        postContent({
          name: item.newName,
          s3_object_key: item.awsKey,
          tags: item.tags,
          sponsor_tags: item.sponsers,
          party_id:
            item.isFillerContent && item.isFillerContent === true
              ? null
              : item.party_id,
          brand_id:
            item.isFillerContent && item.isFillerContent === true
              ? null
              : item.brand_id,
          expiration_date: moment(item.expiration_date).format("YYYY-MM-DD"),
          display_name: item.display_name,
          is_filler_content: item.isFillerContent
        })
      )
      .then(result => {
        if (result.status === 201) {
          const {
            data: { data }
          } = result;
          this.props.AddItemToContentList({ ...data[0] });
        }
        this.props.removeProgress(item.id);
      })
      .then(() => this.props.Get(Resources.getContent))
      .then(() => this.props.Get(ContentTagResources.getContent))
      .then(() => this.props.Get(SponserTagResources.getContent));
  };

  // handleRemoveButtonPressed = (files, item) => {
  //   remove(files, { ...item });
  //   this.props.dispatch(change("ContentUploadForms", "Files", files));
  //   this.forceUpdate();
  // };

  createContentRequestForServer = (values, files) => {
    const newFiles = [];
    files.map(item => {
      const { id } = item;
      Object.keys(values).map(key => {
        const name = values[key];
        const idFromValues = key.substring(6, key.length - 5);
        const tags = values[`media-${id}-tag`];
        const sponsers = values[`media-${id}-sponser`];
        const parties = values[`media-${id}-party`];
        const brands = values[`media-${id}-brand`];
        const isFillerContent = values[`media-${id}-isFillerContent`];
        if (id == idFromValues) {
          const temp = item;
          temp.newName = name;
          temp.tags = tags.map(tag => tag.value);
          temp.awsKey = `${name}-${id}-${moment().unix()}`;
          temp.sponsers = sponsers.map(sponser => sponser.value);
          temp.party_id = parties && parties.key;
          temp.brand_id = brands && brands.key;
          temp.isFillerContent = isFillerContent;
          newFiles.push(temp);
          return key;
        }
        return key;
      });
      return item;
    });
    return newFiles;
  };

  uploadToS3 = async (item, presignedUrl, length) => {
    try {
      const file = item.file;
      const response = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100);
            this.updateToster(progress, item, length, response);
          }
        },
      });

      if (response.status === 200) {
        return item.awsKey; // Return the S3 key for further processing
      } else {
        throw new Error('Failed to upload');
      }
    } catch (error) {
      throw new Error('Upload error: ' + error.message);
    }
  };

  handleSubmit = async values => {
    try{
      const { files } = this.props;
      const newFiles = this.createContentRequestForServer(values, files);
      const file_lists = newFiles.map(item => ({ key: item.awsKey, file_type: item.file.type }));
      const newPresignedUrl = await awsPresignedUrl(file_lists, this.props.Post);
      newFiles.forEach(async item => {
        const itemPresignedUrl = newPresignedUrl[item.awsKey];
        if(itemPresignedUrl === undefined) {
          throw new Error('No presigned url for:' + item.awsKey);
        } else {
        // Upload the file using the pre-signed URL
        const s3Key = await this.uploadToS3(item, itemPresignedUrl, files.length);
        this.updateServer(s3Key, item);
        }
      });
    } catch (error) {
      console.error('Error during upload:', error);
    }
    this.props.history.push("/content");
  };

  handleTagChange = async (values, type) => {
    this.props.files.map(item => {
      const { id } = item;
      let combineTag = createUniqueTags(item[`${type}s`], values);
      Object.keys(this.props.newTags.values).map(key => {
        const idFromValues = key.substring(6, key.length - 5);
        if (id === idFromValues) {
          this.props.dispatch(
            change("Contents", `media-${idFromValues}-${type}`, combineTag)
          );
        }
      });
    });
  };
  handlePartyBrandTagChange = async (values, type) => {
    /***
     * @values define is a object. Has following keys: id, value, label
     * @type define is a string . Its values is either brand or party
     */
    if (type == "party" && values) {
      /*to nullify the value of brand on party change*/
      this.props.dispatch(change("Contents", "bulkBrand", null));
      /*to enable brand tag if party is selected 
      and to fetch data of brand related to selected party*/
      this.setState(state => (state.isParty = false));
      this.props.dispatch(change("Contents", "bulkParty", values));
      let brand = BrandTagResources.getContent(values.key);
      this.props.Get(brand);
    } else if (type == "brand" && values) {
      this.props.dispatch(change("Contents", "bulkBrand", values));
    } else {
      /** the following code handles the party and brand tag in
       * add bulk tag  in content upload */
      if (type == "party") {
        this.setState(state => (state.isParty = true));
        this.props.dispatch(change("Contents", "bulkParty", null));
        this.props.dispatch(change("Contents", "bulkBrand", null));
      } else if (type == "brand") {
        this.props.dispatch(change("Contents", "bulkBrand", null));
      } else {
      }
    }
    this.props.files.map(item => {
      let { id } = item;
      Object.keys(this.props.newTags.values).map(key => {
        const idFromValues = key.substring(6, key.length - 5);
        if (id === idFromValues) {
          this.props.dispatch(
            change(
              "Contents",
              `media-${idFromValues}-${type}`,
              values ? values : null
            )
          );
        }
        /** the following code handles the party and brand tag in
         * single content form in content upload */
        if (type == "party") {
          this.props.dispatch(
            change("Contents", `media-${idFromValues}-brand`, null)
          );
        } else if (type == "party" && values == null) {
          this.props.dispatch(
            change("Contents", `media-${idFromValues}-brand`, null)
          );
          this.setState(state => (state.isParty = true));
        }
      });
    });
  };

  render() {
    const { roles, permission } = this.props;
    return (
      <div className="mt-5">
        {
          <div className="">
            <span className="">
              <strong>Files to Upload</strong>
            </span>
            {/* <StatusButton
handleStatus={this.props.handleStatusFilter}
filterStatus={this.props.filterStatus}
/> */}
            <button
              type="button"
              className="btn-outline-primary"
              onClick={() =>
                this.setState(state => ({
                  displayBulkTagForm: !state.displayBulkTagForm
                }))
              }
            >
              <i className="fa fa-plus mr-2" />
              Add Bulk Tags
            </button>

            {/* <Button
className="addmore"
color="primary"
onClick={() =>
this.props.history.push({
pathname: "/dashboard/content/create",
state: { fromContentUpload: true }
})
}
>
<i className="fas fa-plus mr-2" />
Add More
</Button> */}
          </div>
        }
        <div
          className=" p-4 mt-4 content-wrap"
          style={{ display: this.state.displayBulkTagForm ? "block" : "none" }}
        >
          <div className="mr-2 upload-content rounded">
            <Form inline>
              <FormGroup className="mb-2 bulktag">
                <Label>Bulk Tag</Label>
                <Field
                  component={TagSelect}
                  name="bulkTags"
                  label="Bulk Tag"
                  className="mr-0 mb-0"
                  onChange={e => this.handleTagChange(e, "tag")}
                  options={this.props.ContentTag.map(i => ({
                    value: i,
                    label: i
                  }))}
                />
              </FormGroup>{" "}
              <FormGroup className="mb-2 bulktag">
                <Label>Bulk Sponsor</Label>
                <Field
                  component={TagSelect}
                  name="bulkSponsers"
                  label="Bulk Tag"
                  className="mr-0 mb-0"
                  onChange={e => this.handleTagChange(e, "sponser")}
                  options={this.props.SponserTag.map(i => ({
                    value: i,
                    label: i
                  }))}
                  placeholder="Add Sponsors"
                />
              </FormGroup>
              <RouteAllowed roles={roles} permission={permission.content.contentPartyBrand}>
                <FeatureAllowed feature={"is_party_brand_feature_enabled"}>
                  <FormGroup className="mb-2 bulktag">
                    <Label>Bulk Party</Label>
                    <Field
                      component={SingleTagSelect}
                      name="bulkParty"
                      label="Bulk Tag"
                      className="mr-0 mb-0"
                      onChange={e => this.handlePartyBrandTagChange(e, "party")}
                      options={this.props.PartyTag.map(i => ({
                        key: i.id,
                        value: i.name,
                        label: i.name
                      }))}
                      placeholder="Add Party"
                    />
                  </FormGroup>
                </FeatureAllowed>
                <FeatureAllowed feature={"is_party_brand_feature_enabled"}>
                  {" "}
                  <FormGroup className="mb-2 bulktag">
                    <Label>Bulk Brand</Label>
                    <Field
                      component={SingleTagSelect}
                      name="bulkBrand"
                      label="Bulk Tag"
                      className="mr-0 mb-0"
                      onChange={e => this.handlePartyBrandTagChange(e, "brand")}
                      options={
                        this.props.BrandTag &&
                        this.props.BrandTag.map(i => ({
                          key: i.id,
                          value: i.name,
                          label: i.name
                        }))
                      }
                      placeholder="Add Brand"
                      disabled={this.state.isParty}
                    />
                  </FormGroup>
                </FeatureAllowed>
              </RouteAllowed>
            </Form>
          </div>
        </div>

        <div className="bg-white p-4 mt-4 content-wrap">
          <GenerateForms
            data={this.props.files}
            handleSubmit={this.handleSubmit}
            handleRemove={this.props.handleRemoveButtonPressed}
            ContentTag={this.props.ContentTag}
            filteredTags={this.state.filteredTags}
            handleInputChange={this.handleTagInputChange}
            SponserTag={this.props.SponserTag}
            PartyTag={this.props.PartyTag}
            BrandTag={this.props.BrandTag}
            {...this.props}
          />
          <div className="text-right">
            <div className="mt-1">
              <Link to="/content">
                <button type="button" className="btn-outline-primary">
                  Cancel
                </button>
              </Link>

              <button
                type="button"
                className="primaryButton"
                onClick={this.props.handleSubmit(values =>
                  this.handleSubmit(values)
                )}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    newTags: state.form.Contents,
    bulkParty: contentSelector(state, "bulkParty") || [],
    ContentTag: state.ContentTag.list
      ? state.ContentTag.list.attributes.tags
      : [],
    SponserTag: state.SponserTag.list ? state.SponserTag.list : [],
    PartyTag:
      state.PartyTag.list.parties_list.length > 0
        ? state.PartyTag.list.parties_list
        : [],
    BrandTag: state.BrandTag.list ? state.BrandTag.list.brands_list : []
  }),
  { progress, removeProgress, Post, AddItemToContentList }
)(
  reduxForm({
    form: "Contents"
  })(UploadNewContent)
);
