import React, { Component } from "react";
import {
  reduxForm,
  Field,
  SubmissionError,
  formValueSelector
} from "redux-form";
import { find, toLower, startCase } from "lodash";
import { connect } from "react-redux";
import classnames from "classnames";
import moment from "moment";

import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import ReactPaginate from "react-paginate";

import { Resources as DomainData } from "../redux/domainRedux";
import { Resource as activityResource } from "../redux/activityLogRedux";
import { required, email } from "../../../helper/validation";
import ChangePassword from "../modules/changePasswordModal";
import { Resources, generateUserUpdate } from "../redux/index";
import { Input, Dialog } from "../../../core";
import ActivityLogTable from "../modules/activityLogTable";
import ImageUpload from "../modules/profileImageUpload";
import S3 from "../../../constant/aws";
import axios from "axios";
import { awsPresignedUrl } from "../../../helper/awsPresignedUrl";

const selector = formValueSelector("EditUserForm");

class UserProfile extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      domainLists: [],
      showEdit: false,
      passwordModal: {
        id: undefined,
        modal: false,
        body: "Are you sure you want to delete this content.",
        title: "Confirm Delete"
      },
      isLoading: true,
      activeTab: "1",
      disableUpdateButton: true,
      fileList: [],
      uploading: false,
      isProfilePicFieldTouched: false
    };
    this.perPageCount = 20;
    this.page = 1;
  }

  componentDidMount = () => {
    const { user, userProfileImage } = this.props;

    if (user) {
      this.props.initialize({
        name: user.name || "",
        username: user.username || "",
        email: user.email || "N/A",
        profileImage:
          userProfileImage &&
          userProfileImage.profile_image_url !== null &&
          this.getProfileImage(userProfileImage.profile_image_url)
      });
      const api = [];
      const { getUserDetails } = Resources;
      getUserDetails.url = `/users/${user.id}`;
      api.push(this.props.Get(getUserDetails));
      Promise.all([...api]).then(res => {
        this.props.initialize({
          name: user.name || "",
          username: user.username || "",
          email: user.email || "N/A",
          profileImage: this.getProfileImage(
            res[0].data.data.attributes.profile_image_url
          )
        });
        this.loadActivityLog(1);
        this.showDomains();
      });
    }
  };

  getProfileImage = profileImg => {
    if (profileImg) {
      return [
        {
          url: profileImg
        }
      ];
    }
    return [{ url: require("../../../assets/images/userProfile.png") }];
  };

  loadActivityLog = page => {
    const { getActivityLog } = activityResource;
    getActivityLog.url = `/users/current_user_activities?page=${page}&per_page=${this.perPageCount}`;
    this.props
      .Get(activityResource.getActivityLog)
      .then(() => this.setState({ isLoading: false }));
  };

  toggleTabs = tab => {
    this.setState({ activeTab: tab });
  };

  showDomains = () => {
    const {
      userDomains: {
        attributes: { domain_names }
      }
    } = this.props;
    this.setState({
      domainLists: domain_names
    });
  };

  enableUpdateButton = () => {
    this.setState({ disableUpdateButton: false });
  };

  handleProfilePicFieldTouch = () => {
    this.setState({ isProfilePicFieldTouched: true });
  };

  uploadToS3 = async (item, presignedUrl, length) => {
    try {
      const file = item.profileImage[0].file;
      const response = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (event) => {
          if (event.lengthComputable) {
            const progress = Math.round((event.loaded / event.total) * 100);
            this.updateToster(progress, item, length, response);
          }
        },
      });

      if (response.status === 200) {
        return item.awsKey;
      } else {
        throw new Error('Failed to upload');
      }
    } catch (error) {
      throw new Error('Upload error: ' + error.message);
    }
  };

  handleSubmit = async values => {
    if (this.state.isProfilePicFieldTouched) {
      const item = values;
      const profileImg = values.profileImage[0];
      item.awsKey = `users/profile-image/${profileImg.name}-${moment().unix()}`;
      const file_detail = [{ key: item.awsKey, file_type: profileImg.file.type }];
      const newPresignedUrl = await awsPresignedUrl(file_detail, this.props.Post);
      const itemPresignedUrl = newPresignedUrl[item.awsKey];
      if(itemPresignedUrl === undefined) {
        throw new Error('No presigned url for:' + item.awsKey);
      } else {
        // Upload the file using the pre-signed URL
        const s3Key = await this.uploadToS3(item, itemPresignedUrl, profileImg);
        this.updateServer(s3Key, item);
      }
    } else {
      this.setState({ uploading: true });
      this.updateServer({}, values);
    }
  };

  updateToster = (p, item, length) => {
    this.setState({ uploading: true });
    return 0;
  };

  updateServer = async (awsData, metaInfo) => {
    const data = {
      name: metaInfo.name,
      email: metaInfo.email,
      username: metaInfo.username,
      profile_image_key: this.state.isProfilePicFieldTouched
        ? metaInfo.awsKey
        : undefined
    };

    const { updateUser } = Resources;

    updateUser.url = `/users/${this.props.user.id}`;
    updateUser.body = generateUserUpdate(this.props.user.id, { data });
    const result = await this.props.Put(updateUser);
    if (result.status === 200) {
      this.props.history.push("/profile");
      this.props.Get(Resources.getUserProfileImage);
      this.setState({
        uploading: false,
        disableUpdateButton: true,
        isProfilePicFieldTouched: false
      });
      return 0;
    }
    if (result.data.hasOwnProperty("error")) {
      this.setState({
        uploading: false,
        disableUpdateButton: true,
        isProfilePicFieldTouched: false
      });
      return 0;
    }
    throw new SubmissionError({
      email: "Something Went Wrong"
    });
  };

  toggleEditForm() {
    this.setState({
      showEdit: !this.state.showEdit
    });
  }

  handlePageClick = (data, moveToPrevious = false) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    const { getActivityLog } = activityResource;
    if (moveToPrevious) {
      getActivityLog.url = `/users/current_user_activities?page=${data.page -
        1}&per_page=${this.perPageCount}`;
      this.props.Get(getActivityLog).then(() => {
        this.setState({ forcePage: data.page - 2, isLoading: false });
      });
    } else {
      this.setState({ forcePage: undefined });
      this.setState({ isLoading: true });
      const { selected } = data;
      getActivityLog.url = `/users/current_user_activities?page=${selected +
        1}&per_page=${this.perPageCount}`;
      this.props
        .Get(getActivityLog)
        .then(() => this.setState({ forcePage: selected, isLoading: false }));
    }
  };

  onAddFile = fileList => {
    this.setState({ fileList });
  };

  render() {
    if (this.props.user === undefined) {
      this.props.history.push("/campaign/");
      return null;
    }
    const { user, handleSubmit, subDomainUser } = this.props;
    const domainRoles = JSON.parse(window.localStorage.getItem("domain")).roles;
    const { activeTab } = this.state;
    // const { roles } = this.props;
    return (
      <div className="mainPage">
        <div className="contentSection userProfile">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  this.toggleTabs("1");
                }}
              >
                Information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  this.toggleTabs("2");
                }}
              >
                Activity Log
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="col-md-6 networkInformation clearfix">
                <div className="">
                  <h4 className="userProfile__headerTitle">
                    <i className="mr-3 fas fa-user" />
                    Profile
                  </h4>
                  <div className="clearfix">
                    <div className="float-left">
                      <Field
                        component={ImageUpload}
                        name="profileImage"
                        onAddFile={this.onAddFile}
                        currentImage={this.props.profileImage}
                        enableUpdateButton={this.enableUpdateButton}
                        handleProfilePicFieldTouch={
                          this.handleProfilePicFieldTouch
                        }
                      />
                    </div>

                    <div className="userProfile__block">
                      <p>
                        <span>Name</span>
                        {user.name || "N/A"}
                      </p>
                      <p>
                        <span>Email</span>
                        {user.email}
                      </p>
                      <p>
                        <span>Roles</span>
                        <p className="roles-block">
                          {domainRoles.map(item => (
                            <span
                              key={JSON.stringify(item)}
                              className="userProfile__roles"
                            >
                              <b className={`roles ${toLower(item)} `}>
                                {startCase(item)}
                              </b>
                            </span>
                          ))}
                        </p>
                      </p>
                    </div>
                  </div>

                  <div className="userProfile__info">
                    <h4 className="userProfile__headerTitle">
                      {" "}
                      <i className="mr-3 bx bxs-pencil" />
                      Edit Info
                    </h4>
                    <form className="validate-form">
                      <div className="form-validation-arrange userinput">
                        <Field
                          component={Input}
                          name="name"
                          type="text"
                          validate={required}
                          placeholder="Name"
                          label="Name"
                          onChange={this.enableUpdateButton}
                        />
                      </div>

                      <div className="form-validation-arrange userinput">
                        <Field
                          component={Input}
                          name="email"
                          type="email"
                          placeholder="Email"
                          validate={[required, email]}
                          label="Email"
                          disabled="true"
                        />

                        <div className="userProfile__changePassword mt-5">
                          <ChangePassword
                            Put={this.props.Put}
                            id={this.props.user.id}
                          />
                          <div className="d-flex justify-content-end">
                            {/* <button
                              type="button"
                              className="btn-outline-primary"
                              onClick={this.toggleEditForm.bind(this)}
                            >
                              Cancel
                            </button> */}
                            <button
                              className="primaryButton mt-0"
                              type="submit"
                              onClick={handleSubmit(values =>
                                this.handleSubmit(values)
                              )}
                              disabled={this.state.disableUpdateButton}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <ActivityLogTable
                activityLog={this.props.activityLog}
                isLoading={this.state.isLoading}
              />
              {!this.state.isLoading &&
                this.props.activityLog &&
                this.props.activityLog.length > 0 && (
                  <div className="contentSectionPagination clearfix">
                    <ReactPaginate
                      pageCount={
                        this.props.meta ? this.props.meta.totalPages : 0
                      }
                      pageRangeDisplayed={3}
                      onPageChange={this.handlePageClick}
                      marginPagesDisplayed={2}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                      previousLinkClassName="pagination-label
                  "
                      nextLinkClassName="pagination-label"
                      previousLabel="<"
                      forcePage={this.state.forcePage}
                      nextLabel=">"
                      breakLabel="..."
                      breakClassName="break-me"
                      disabledClassName="paginationDisable"
                    />
                  </div>
                )}
            </TabPane>
          </TabContent>
          {this.state.uploading && (
            <div
              id="notification_toast-flash"
              className="notification_toast info animated fadeInUp"
            >
              <p>
                Updating user details <i className="fa fa-spinner fa-spin" />
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const ProfileComponent = reduxForm({
  form: "EditUserForm"
})(UserProfile);

// export default ProfileComponent;
export default connect(state => ({
  user: state.userDetails.user,
  subDomainUsers: state.userDetails.subDomainUsers,
  userProfileImage:
    state.userProfileImage.list && state.userProfileImage.list.attributes,
  userDomains: state.userDomainDetails.list,
  activityLog: state.activityLog.list || [],
  meta: state.activityLog.meta,
  profileImage: selector(state, "profileImage")
}))(ProfileComponent);
